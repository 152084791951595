exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cenik-tsx": () => import("./../../../src/pages/cenik.tsx" /* webpackChunkName: "component---src-pages-cenik-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-tsx": () => import("./../../../src/pages/info.tsx" /* webpackChunkName: "component---src-pages-info-tsx" */),
  "component---src-pages-piercing-tsx": () => import("./../../../src/pages/piercing.tsx" /* webpackChunkName: "component---src-pages-piercing-tsx" */),
  "component---src-pages-pmu-tsx": () => import("./../../../src/pages/pmu.tsx" /* webpackChunkName: "component---src-pages-pmu-tsx" */)
}

